<template>
  <div class="list-box">
    <!--侧边新闻列表-->
    <left-news-data-list
      title="媒体报道"
      :caseCenterList="caseCenterList"
      :currentNewsId="currentNewsId"
      :currentPage="pagination.currentPage"
      :totalPage="pagination.totalPage"
      @changeNews="changeNews"
      @viewMore="viewMore"
      @pageNext="pageNext"
      @pagePre="pagePre"
    />
    <div class="main-box">
      <breadcurmb :menuList="menuList"></breadcurmb>
      <!--    <div class="title">-->
      <!--      <div>动态详情</div>-->
      <!--      <div class="bottom-line"></div>-->
      <!--    </div>-->
      <div v-loading="loading" style="min-height: 500px">
        <el-empty description="暂无数据" v-if="!newsInfo.title && !loading"></el-empty>
        <div class="news-box" v-else>
          <div class="news-title">{{ newsInfo.title }}</div>
          <div class="news-msg">
            <img src="@assets/caseCenter/p-time.png" alt="">
            <span>
         发布时间：{{ newsInfo.releaseTime }}
        </span>
            <span>
           来源：
          <a
            target="_blank"
            :href="newsInfo.sourceUrl">
        {{ newsInfo.source }}</a>
        </span>
            <img src="@assets/caseCenter/views.png" alt="">
            <span>
          浏览次数：{{ newsInfo.viewCount }}
        </span>
          </div>
          <div class="news-content" v-html="newsInfo.content"></div>
        </div>
      </div>
      <div class="btn-box">
      <span class="other-title" @click="handlePrevious(newsInfo.previousId)">上一篇：{{
          newsInfo.previousTitle || '暂无更多'
        }}</span>
        <span class="other-title" @click="handleNext(newsInfo.nextId)">下一篇：{{
            newsInfo.nextTitle || '暂无更多'
          }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb'
  import { getAction } from '@api/manage'
  import { message } from 'ant-design-vue'
  import LeftNewsDataList from '../common/LeftNewsDataList'


  export default {
    name: 'AuditListDetail',
    components: { LeftNewsDataList, Breadcurmb },
    data() {
      return {
        loading: false,
        caseCenterList: [],
        newsInfo: {},
        pageId: '',
        type: '',
        menuList: [
          {
            name: '媒体报道',
            path: '/bayareacertification/mediaCoverage'
          },
          {
            name: '媒体报道详情',
            path: ''
          }
        ],
        currentNewsId: '',
        pagination: {
          currentPage: 1,
          pageSize: 10,
          totalPage: 0
        }
      }
    },
    computed: {
      index: function () {
        return this.caseCenterList.findIndex(item => item.id === this.pageId)
      }
    },
    created() {
      this.pageId = this.$route.query.id
      this.type = this.$route.query.type
      this.currentNewsId = this.pageId
      this.loadAllNewsData()
      this.getNewsDetail(this.pageId)
    },
    methods: {
      loadAllNewsData(arg, isLoading = true) {
        return new Promise((resolve, reject) => {
          let params = {
            dynamicType: '1',
            queryPage: this.pagination.currentPage,
            querySize: this.pagination.pageSize
          }
          getAction('/api/v1/dms/centralDynamics/detail', params).then((res) => {
            if (res.success) {
              if (res && res.list && res.list.length > 0) {
                this.caseCenterList = res.list.map(item => {
                  return {
                    id: item.id,
                    coverPictureUrl: item.coverImage,
                    title: item.title,
                    date: item.releaseTime.slice(0, 10),
                    content: item.content
                  }
                })
                this.pagination.currentPage = res.page
                this.pagination.pageSize = res.size
                this.pagination.totalPage = res.totalPage
              } else {
                this.caseCenterList = []
              }
              resolve(res)
            } else {
              this.caseCenterList = []
              message.error(res.message)
              reject(res)
            }
          }).catch((err) => {
            this.caseCenterList = []
            message.error(err.message)
            reject(err)
          })
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
        })
      },
      getNewsDetail(id) {
        this.loading = true
        getAction(`/api/v1/dms/centralDynamics/queryDetail/${id}?dynamicType=${this.type}`, {}).then((res) => {
          if (res.success) {
            if (res && res.data) {
              this.newsInfo = res.data
            } else {
              this.newsInfo = {}
            }
          } else {
            this.newsInfo = {}
            message.error(res.message)
          }
        }).catch((err) => {
          this.newsInfo = {}
          message.error(err.message)
        }).finally(() => {
          this.loading = false
        })
      },
      viewMore() {
        this.$router.push({ path: '/bayareacertification/mediaCoverage' })
      },
      changeNews(record) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        this.currentNewsId = record.id
        this.getNewsDetail(record.id)
      },
      pageNext() {
        if (this.pagination.currentPage < this.pagination.totalPage) {
          this.pagination.currentPage++
          this.loadAllNewsData()
        } else {
          this.isPageNext = false
        }
      },
      pagePre() {
        if (this.pagination.currentPage <= 1) {
          this.isPagePre = false
          return
        }
        this.pagination.currentPage--
        this.loadAllNewsData()
      },
      // 上一篇
      handlePrevious(id) {
        if (!id) {
          this.$message({
            message: '已经是第一篇报道了~',
            type: 'warning'
          })
        } else {
          // 回到顶部，添加过渡动画
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          this.pageId = id
          this.currentNewsId = this.pageId
          this.getNewsDetail(this.pageId)
        }
        this.$nextTick(() => {
          this.$zh_tran(localStorage.getItem('lang'))
        })
      },
      // 下一篇
      handleNext(id) {
        if (!id) {
          this.$message({
            message: '已经是最后一篇报道了~',
            type: 'warning'
          })
        } else {
          // 回到顶部
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          this.pageId = id
          this.currentNewsId = this.pageId
          this.getNewsDetail(this.pageId)
        }
        this.$nextTick(() => {
          this.$zh_tran(localStorage.getItem('lang'))
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.list-box {
  display: flex;
  justify-content: center;
  margin: 85px 0 0 -200px;
  background-color: #cccccc20;
}

.main-box {
   width: 1000px;
  margin-left: 15px;
  margin-bottom: 30px;
  padding: 0 20px 30px;
  background-color: #ffffff;
  border-radius: 5px;

  .title {
    margin: 33px 0 20px 0;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }

  .news-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .btn-box {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .other-title {
      display: block;
      font-size: 16px;
      color: @primary-color;
      font-weight: bold;
      padding-bottom: 1px;
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      cursor: pointer;

      &:hover {
        opacity: 0.85;
        padding-bottom: 0;
        border-bottom: 1px solid @primary-color;
      }
    }
  }

  .news-title {
    font-size: 24px;
    color: @primary-color;
    font-weight: bold;
    margin: 20px 0;
  }

  .news-msg {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #A19F9F;
    margin-bottom: 20px;

    img {
      margin-right: 3px;
    }

    span {
      margin-right: 30px;
    }
  }

  .news-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
  }
}

/deep/ .el-button--primary {
  background-color: @primary-color;
  border-color: @primary-color;
  color: #ffffff;

  &:hover {
    opacity: 0.85;
  }
}
</style>
